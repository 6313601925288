import store from "@/state/store";

const currentEntity = store.getters["auth/currentEntity"];

const id = currentEntity.id;

export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "Dashboard",
    icon: "ri-dashboard-line",
    link: `/entity/${id}/dashboard`,
  },
  {
    id: 3,
    label: "Courses",
    icon: "fas fa-graduation-cap",
    subItems: [
      {
        id: 4,
        label: "New Course",
        link: `/entity/${id}/course/add`,
      },
      {
        id: 5,
        label: "Courses",
        link: `/entity/${id}/courses`,
      },
    ],
  },
  {
    id: 18,
    isLayout: true,
  },
];
